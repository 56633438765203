<template>
  <div class="w-full py-2">
      <div class="flex w-full p-4 mt-4">
        <img class="mr-3" src="../../../../../../assets/images/icon_caja.svg" alt="">
        <p class="text-xl text-blue-800 font-bold">Recepción Pedidos</p>
      </div>
      <div class="mt-2 w-full">
        <div class="card" role="group">
            <TabMenu :model="items" v-model:activeIndex="active"/>
          <router-view/>
        </div>
      </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { can as $c } from '../../../../../../utils/permission'
import { menuCollapse } from '@/utils/collapseMenu'
export default {
  name: 'index',
  setup () {
    const active = ref(0)
    const itemsTab = ref(
      [
        {
          label: 'Pedidos',
          icon: 'pi pi-table',
          to: 'listar-pedidos',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos']
        },
        {
          label: 'Listado de Recepciones',
          icon: 'pi pi-list',
          to: 'listar-recepciones',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones', 'pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-bodega']
        },
        {
          label: 'Listado entradas pendientes',
          icon: 'pi pi-list',
          to: 'listar-entradas-pendientes',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones', 'pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras']
        },
        {
          label: 'Listado de Entradas Mercancía',
          icon: 'pi pi-wallet',
          to: 'listar-entradas-de-mercancia',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.listado-entradas']
        },
        {
          label: 'Crear Devoluciones',
          icon: 'pi pi-replay',
          to: 'listar-entradas-de-mercancia-devoluciones',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.devoluciones']
        },
        {
          label: 'Cargar XML',
          icon: 'pi pi-upload',
          to: 'recepcion-pedidos-xml',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.devoluciones'] // temporal
        },
        {
          label: 'Listado XML',
          icon: 'pi pi-folder-open',
          to: 'listado-recepcion-pedidos-xml',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.devoluciones'] // temporal
        },
        {
          label: 'Configuración',
          icon: 'pi pi-cog',
          to: 'configuracion',
          permiso: ['pharmasan.compras.logistica.recepcion-pedidos.configuracion']
        }
      ]
    )
    const items = computed(() => {
      return itemsTab.value.filter((x) => x.permiso.every(a => $c(a)))
    })

    onMounted(() => {
      menuCollapse()
    })
    return {
      items,
      active
    }
  }
}
</script>

<style scoped lang="scss">
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #1c3faa;
  color: #1c3faa;
}
::v-deep(.p-tabmenu-nav){
  display: -webkit-box !important;
}
</style>
